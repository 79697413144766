import React from 'react';

export const initialState = {
  building: '',
  streetName: '',
  city: '',
  county: '',
  country: '',
  postcode: '',
  phoneNumber: '',
  hideBuilding: false,
  fillingProgress: 0,
  id: '',
  ownerId: '',
  propertyRight: '',
  bedrooms: 0,
  bathrooms: 0,
  ensuites: 0,
  type: '',
  typeOption: '',
  garden: '',
  parking: [],
  carParkSpaces: 0,
  tags: [],
  photos: [],
  floorplans: [],
  documents: [],
  size: '',
  price: 0,
  summary: '',
  description: '',
  videoLink: '',
  createdAt: '',
  updatedAt: '',
  editForm: false,
  fillingProgressFrontEnd: 0,
  geolocation: {
    latitude: 0,
    longitude: 0,
  },
  services: [],
  isUpdatingAfterPublishing: false,
};
const ListYourHouseContext = React.createContext(initialState);

export default ListYourHouseContext;
