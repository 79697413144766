/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import cx from 'classnames';
import PlacesAutocomplete from 'react-places-autocomplete';
import { InputBase, Paper, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import searchOptions from '../../helpers/googlePlace/placeAutoCompliteOptions';
import useStyles from './useStyles';
import styles from './textFieldAutoComplite.module.scss';

const regExpAutoComplite = /^[A-Za-z0-9_,/-\s]+$/;

function TextFieldAutoComplete({ handleSearchItems, placeholder = '' }) {
  const [address, setAddress] = useState('');

  const classes = useStyles();

  const handleSelect = async (add) => {
    setAddress(add);
    handleSearchItems(add, setAddress);
  };

  const handleChangeInputValue = (value) => {
    if (value === '' || regExpAutoComplite.test(value)) {
      setAddress(value);
    }
  };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={address}
      onChange={handleChangeInputValue}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={styles.textFieldAutoCompliteContainer}>
          <Paper className={classes.root}>
            <SearchIcon className={classes.iconButton} fontSize="large" />
            <InputBase
              fullWidth
              variant="outlined"
              {...getInputProps({
                placeholder,
                className: cx(styles.searchInput),
              })}
            />
          </Paper>
          <div className={styles.autocompliteDropdown}>
            {loading && (
              <MenuItem className={classes.loading}>Loading...</MenuItem>
            )}
            {suggestions.map((suggestion) => {
              const className = cx(styles.suggestion, {
                [styles.suggestionActive]: suggestion.active,
              });

              return (
                <MenuItem
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                >
                  {suggestion.description}
                  ...
                </MenuItem>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default TextFieldAutoComplete;
