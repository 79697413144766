import * as React from 'react';

function Logo() {
  return (
    <svg width={132} height={21} fill="none">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M129.436 18.453c.856 0 1.709-.12 2.564-.362v2.366c-.388.15-.889.275-1.507.374-.605.107-1.235.16-1.892.16-3.32 0-4.978-1.568-4.978-4.703V8.327h-2.252v-1.39l2.416-1.148 1.192-3.126h2.161V5.95h4.696v2.378h-4.696v7.91c0 .756.21 1.316.626 1.681.428.356.984.535 1.67.535zM119.494 16.515c0 1.443-.587 2.551-1.76 3.327-1.174.766-2.851 1.148-5.039 1.148-2.195 0-3.959-.298-5.291-.895v-2.71c1.937.8 3.741 1.202 5.412 1.202 2.156 0 3.234-.584 3.234-1.752 0-.373-.12-.684-.358-.935-.237-.248-.631-.507-1.177-.774-.547-.268-1.308-.57-2.281-.909-1.897-.66-3.186-1.318-3.862-1.977-.665-.66-.997-1.514-.997-2.565 0-1.264.565-2.244 1.699-2.939 1.143-.703 2.693-1.055 4.651-1.055 1.938 0 3.771.352 5.5 1.055l-1.132 2.365c-1.779-.66-3.275-.989-4.487-.989-1.849 0-2.772.472-2.772 1.416 0 .463.237.855.715 1.175.485.321 1.539.762 3.159 1.323 1.361.472 2.35.904 2.967 1.296.617.391 1.074.845 1.371 1.363.299.508.448 1.118.448 1.83zM103.394 20.99h-3.505V6.213h3.505v14.775zM99.682 2.3c0-.56.169-.993.506-1.295.348-.303.84-.454 1.477-.454.614 0 1.092.15 1.429.454.348.302.522.734.522 1.296 0 .534-.174.957-.522 1.268-.337.303-.815.455-1.429.455-.637 0-1.129-.151-1.477-.455-.337-.311-.506-.734-.506-1.268zM94.866 20.99h-3.504V.204h3.504V20.99z"
          fill="#8E99AC"
        />
        <path
          d="M85.817 14.881c0 1.97-.723 3.483-2.175 4.54-1.45 1.046-3.526 1.569-6.231 1.569-2.716 0-4.897-.408-6.544-1.222v-3.7c2.397 1.092 4.628 1.64 6.692 1.64 2.668 0 4-.796 4-2.389 0-.51-.146-.936-.442-1.276-.294-.34-.78-.692-1.456-1.058-.676-.364-1.617-.778-2.821-1.24-2.348-.899-3.94-1.799-4.775-2.699-.823-.899-1.235-2.066-1.235-3.5 0-1.725.701-3.062 2.102-4.01 1.413-.961 3.33-1.441 5.75-1.441 2.398 0 4.666.48 6.803 1.44l-1.4 3.227c-2.2-.9-4.05-1.35-5.55-1.35-2.286 0-3.428.645-3.428 1.934 0 .632.295 1.167.884 1.604.602.438 1.906 1.04 3.908 1.805 1.685.645 2.907 1.234 3.67 1.769.762.535 1.327 1.155 1.696 1.86.369.692.552 1.526.552 2.497zM62.458 20.63l-.614-2.668h-.224c-.608.945-1.476 1.688-2.604 2.226-1.117.54-2.394.81-3.834.81-2.492 0-4.353-.614-5.58-1.84-1.228-1.227-1.842-3.086-1.842-5.575V.278h4.41v12.55c0 1.558.322 2.73.967 3.515.645.773 1.655 1.16 3.032 1.16 1.836 0 3.182-.54 4.038-1.62.868-1.092 1.303-2.914 1.303-5.466V.278h4.39V20.63h-3.442z"
          fill="#C92B32"
        />
        <path
          d="M30.27 9.52h3.283v3.247H30.27V9.521zM30.27 14.3h3.283v3.246H30.27V14.3z"
          fill="#E18A8E"
        />
        <path d="M35.54 9.52h3.282v3.247h-3.283V9.521z" fill="#C92B32" />
        <path d="M35.54 14.3h3.282v3.246h-3.283V14.3z" fill="#E18A8E" />
        <path
          d="M42.635 21V8.144h1.27V20.25c0 .572-.165.75-.83.75h-.44zM35.085.274a.636.636 0 01-.08.9L23.63 10.64a.649.649 0 01-.906-.079l-.041-.048a.636.636 0 01.08-.899L34.14.148a.649.649 0 01.906.08l.04.046z"
          fill="#C92B32"
        />
        <path
          d="M46.369 10.563a.649.649 0 01-.907.079L34.087 1.174a.636.636 0 01-.079-.899l.04-.048a.648.648 0 01.907-.079L46.33 9.617c.27.225.306.63.079.898l-.04.048zM26.457 21V8.144h-1.27V20.25c0 .572.166.75.83.75h.44zM21.451 20.997h-4.465V13.95H4.446v7.047H0V.277l4.447.368v8.372h12.539V.277h4.465v20.72z"
          fill="#C92B32"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h132v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoLogo = React.memo(Logo);
export default MemoLogo;
