/* eslint-disable consistent-return */
import { useState, useEffect, useCallback } from 'react';

export default (axios) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState({});

  const doFetch = useCallback((data = {}) => {
    setOptions(data);
    setIsLoading(true);
  }, []);

  const doReset = useCallback(() => {
    setResponse(null);
    setIsLoading(false);
    setError(null);
  }, []);

  useEffect(() => {
    let skipGetResponseAfterDestroy = false;
    if (!isLoading) {
      return;
    }
    axios(options)
      .then((res) => {
        if (!skipGetResponseAfterDestroy) {
          setResponse(res);
          setIsLoading(false);
          setError(null);
        }
      })
      .catch((err) => {
        if (!skipGetResponseAfterDestroy) {
          setError(err);
          setIsLoading(false);
        }
      });

    return () => {
      skipGetResponseAfterDestroy = true;
    };
  }, [isLoading, axios, options]);

  return [{ isLoading, response, error }, doFetch, doReset];
};
