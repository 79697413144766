/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { isAuthenticated } from '../helpers/axiosRefreshToken';
import ListYourHouseContext from '../pages/ListYourHouse/context';
import UserContext from '../pages/AccountInfo/context';
import ErrorBoundary from '../components/ErrorBoundary';
import { RESET_STATE_LISTING_YOUR_HOUSE } from '../pages/ListYourHouse/reducer/actionTypes';
import { DELETE_USER_INFO } from '../pages/AccountInfo/reducer/actionTypes';


const PrivateRoute = ({ component: Component, ...rest }) => {
  const { dispatch } = useContext(ListYourHouseContext);
  const { dispatchUser } = useContext(UserContext);
  const location = useLocation();


  if (!isAuthenticated()) {
    dispatchUser({
      type: DELETE_USER_INFO,
    });
    dispatch({
      type: RESET_STATE_LISTING_YOUR_HOUSE,
    });
  }
  return (
    <ErrorBoundary key={location?.pathname}>
      <Route
        {...rest}
        render={(props) => (isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/log-in',
              state: { from: props.location },
            }}
          />
        ))}
      />
    </ErrorBoundary>
  );
};

export default PrivateRoute;
