export const SET_INPUT_VALUE = 'LIST_YOUR_HOUSE/SET_INPUT_VALUE';
export const SET_INPUT_VALUE_GOOGLE = 'LIST_YOUR_HOUSE/SET_INPUT_VALUE_GOOGLE';
export const SET_CHECKBOX_VALUE = 'LIST_YOUR_HOUSE/SET_CHECKBOX_VALUE';
export const CLEAR_ADDRESS_FORM_VALUE = 'LIST_YOUR_HOUSE/CLEAR_ADDRESS_FORM_VALUE';
export const SET_VALUE_BACKEND = 'LIST_YOUR_HOUSE/SET_VALUE_BACKEND';
export const SET_FILLING_PROGRESS_STATE = 'LIST_YOUR_HOUSE/SET_FILLING_PROGRESS_STATE';
export const SET_FILLING_PROGRESS_STATE_FROM_BACKEND = 'LIST_YOUR_HOUSE/SET_FILLING_PROGRESS_STATE_FROM_BACKEND';
export const SET_FORM_EDITING_FLAG = 'LIST_YOUR_HOUSE/SET_FORM_EDITING_FLAG';
export const RESET_STATE_LISTING_YOUR_HOUSE = 'LIST_YOUR_HOUSE/RESET_STATE';

export const SET_INITIAL_PROPERTY_TYPE = 'LIST_YOUR_HOUSE/SET_INITIAL_PROPERTY_TYPE';

export const SET_GEOLOCATION = 'LIST_YOUR_HOUSE/SET_GEOLOCATION';

export const SET_PARKING_VALUE = 'LIST_YOUR_HOUSE/SET_PARKING_VALUE';
export const SET_PARKING_VALUE_FLAG = 'LIST_YOUR_HOUSE/SET_PARKING_VALUE_FLAG';
export const DELETE_PARKING_VALUES = 'LIST_YOUR_HOUSE/DELETE_PARKING_VALUES';

export const ADD_PHOTO = 'LIST_YOUR_HOUSE/ADD_PHOTO';
export const DELETE_PHOTO = 'LIST_YOUR_HOUSE/DELETE_PHOTO';
export const SET_MAIN_PHOTO = 'LIST_YOUR_HOUSE/SET_MAIN_PHOTO';

export const ADD_FLOORPLAN = 'LIST_YOUR_HOUSE/ADD_FLOORPLAN';
export const DELETE_FLOORPLAN = 'LIST_YOUR_HOUSE/DELETE_FLOORPLAN';

export const ADD_DOCUMENTS = 'LIST_YOUR_HOUSE/ADD_DOCUMENTS';
export const DELETE_DOCUMENTS = 'LIST_YOUR_HOUSE/DELETE_DOCUMENTS';

export const SET_SERVICES = 'LIST_YOUR_HOUSE/SET_SERVICES';

export const SET_FLAG_UPDATING_AFTER_PUBLISHING = 'LIST_YOUR_HOUSE/SET_FLAG_UPDATING_AFTER_PUBLISHING';


