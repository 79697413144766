import React from 'react';

const Home = React.lazy(() => import('../pages/Home'));
const SignUp = React.lazy(() => import('../pages/SignUp'));
const LogIn = React.lazy(() => import('../pages/LogIn'));
const FAQs = React.lazy(() => import('../pages/FAQs'));
const TermsConditions = React.lazy(() => import('../pages/TermsConditions'));
const PrivatePolicy = React.lazy(() => import('../pages/PrivatePolicy'));
const ServiceAgreement = React.lazy(() => import('../pages/ServiceAgreement'));
const PropertySearch = React.lazy(() => import('../pages/PropertySearch'));
const DetailPropertyView = React.lazy(() => import('../pages/PropertySearch/DetailPropertyView'));
const NotFoundPage = React.lazy(() => import('../pages/NotFound'));

const routes = [
  {
    path: '/',
    Component: Home,
    id: 1,
  },
  {
    path: '/sign-up',
    Component: SignUp,
    id: 2,
  },
  {
    path: '/log-in',
    Component: LogIn,
    id: 3,
  },
  {
    path: '/faq',
    Component: FAQs,
    id: 4,
  },
  {
    path: '/terms-and-conditions',
    Component: TermsConditions,
    id: 5,
  },
  {
    path: '/privacy-policy',
    Component: PrivatePolicy,
    id: 6,
  },
  {
    path: '/service-agreement',
    Component: ServiceAgreement,
    id: 7,
  },
  {
    path: '/property-search-results',
    Component: PropertySearch,
    id: 8,
  },
  {
    path: '/property-search-results/property-details',
    Component: DetailPropertyView,
    id: 9,
  },
  {
    path: '/404',
    Component: NotFoundPage,
    id: 10,
  },
];
export default routes;
