import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Logo from '../Logo';
import ContactIcon from './Icons/ContactIcon';
import GrassIcon from './Icons/GrassIcon';
import styles from './footer.module.scss';

function Footer() {
  const location = useLocation();

  const reloadThePage = () => {
    if (location.pathname === '/') {
      window.location.reload();
    }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <div className={styles.logo}>
            <NavLink to="/" onClick={reloadThePage} className={styles.logoLink}>
              <Logo />
            </NavLink>
            <div className={styles.copyright}>
              Copyright Haus List Ltd. 2021
            </div>
          </div>
          <div className={styles.info}>
            <NavLink to="/terms-and-conditions" className={styles.infoNavLink}>
              Terms &#38; conditions
            </NavLink>
            <NavLink to="service-agreement" className={styles.infoNavLink}>
              Service agreement
            </NavLink>
            <NavLink to="privacy-policy" className={styles.infoNavLink}>
              Privacy Policy
            </NavLink>
          </div>
        </div>

        <div className={styles.rightSide}>
          <ul className={styles.nav}>
            <li className={styles.navItem}>
              <HashLink to="/faq#about" className={styles.navLink}>
                About us
              </HashLink>
            </li>
            <li className={styles.navItem}>
              <HashLink className={styles.navLink} to="/#howItWorks">
                How it Works
              </HashLink>
            </li>
            <li className={styles.navItem}>
              <NavLink to="/faq" className={styles.navLink}>
                FAQ
              </NavLink>
            </li>
          </ul>

          <div className={styles.contactUs}>
            <a
              href="mailto:contact@hauslist.co.uk"
              className={styles.contactUsLink}
            >
              <ContactIcon />
              Contact us
            </a>
          </div>
        </div>

        <div className={styles.grass}>
          <GrassIcon />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
