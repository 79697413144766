import React, { useContext, useEffect } from 'react';

import { isAuthenticated } from '../../helpers/axiosRefreshToken';
import { getUserInfo } from '../../api/endpoints/user';
import ListYourHouseContext from '../../pages/ListYourHouse/context';
import useFetch from '../../customHooks/useFetch';
import UserContext from '../../pages/AccountInfo/context';
import { SET_USER_INFO } from '../../pages/AccountInfo/reducer/actionTypes';
import Navbar from './Navbar';
import styles from './header.module.scss';

function Header() {
  const { dispatch } = useContext(ListYourHouseContext);
  const {
    stateUser: { firstName, lastName },
    dispatchUser,
  } = useContext(UserContext);
  const [{ response: responseGetUserData }, doFetchUserData] = useFetch(getUserInfo);

  useEffect(() => {
    if (isAuthenticated()) {
      doFetchUserData();
    }
  }, [doFetchUserData]);

  useEffect(() => {
    if (responseGetUserData) {
      const userInfo = {
        firstName: responseGetUserData.firstName,
        lastName: responseGetUserData.lastName,
        email: responseGetUserData.email,
        emailVerified: responseGetUserData.emailVerified,
        verified: responseGetUserData.verified,
      };
      dispatchUser({
        type: SET_USER_INFO,
        payload: userInfo,
      });
    }
  }, [responseGetUserData, dispatchUser]);

  return (
    <header className={styles.header}>
      <Navbar dispatch={dispatch} firstName={firstName} lastName={lastName} />
    </header>
  );
}
export default Header;
