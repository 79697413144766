const changeRoutesOnKeyPress = (name, e, history, removeAuthData) => {
  if (e.key === 'Enter') {
    switch (name) {
      case 'sellerDashboard':
        history.push('./my-seller-dashboard');
        break;
      case 'buyerDashboard':
        history.push('./my-buyer-dashboard');
        break;
      case 'myAccount':
        history.push('./my-account');
        break;
      case 'draft':
        history.push('./list-your-house/select-draft');
        break;
      case 'logOut':
        removeAuthData();
        history.push('/');
        break;
      default:
        break;
    }
  }
};

export default changeRoutesOnKeyPress;
