import React from 'react';

function ContactIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        fill="#2B2D40"
        d="M13.888 2a5.1 5.1 0 000 2H2.473l7.437 6.662 4.973-4.52c.42.527.943.966 1.54 1.285L9.92 13.338 1.97 6.216V18h15.76V7.9c.65.134 1.319.134 1.969 0V19c0 .265-.104.52-.288.707a.978.978 0 01-.697.293H.985a.978.978 0 01-.697-.293A1.008 1.008 0 010 19V3c0-.265.104-.52.288-.707A.978.978 0 01.985 2h12.903zm4.826 4a2.917 2.917 0 01-2.09-.879 3.04 3.04 0 010-4.243c.275-.278.6-.499.96-.65a2.917 2.917 0 013.22.65c.554.563.865 1.326.865 2.122 0 .796-.311 1.559-.866 2.121A2.933 2.933 0 0118.715 6z"
      />
    </svg>
  );
}

export default ContactIcon;
