/* eslint-disable no-console */
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './errorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className={styles.errorBoundary}>
          <h1 className={styles.heading}>
            Something went wrong! Try again or contact the administrator
            info@hauslist.co.uk
          </h1>
          <Link to="/">
            <h1 className="description">Go Home</h1>
          </Link>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
