import React from 'react';

function GrassIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="33"
      fill="none"
      viewBox="0 0 62 33"
    >
      <path
        fill="#96DAD5"
        d="M1.123 17.496c.64-.801 5.186.387 9.307 3.73.898.728 1.723 1.478 2.449 2.218-.4-.788-.781-1.68-1.113-2.647-1.223-3.574-.928-6.92-.24-7.158.682-.241 2.963 2.209 4.186 5.782.487 1.417.795 2.775.921 3.926.09-.26.178-.515.278-.774.568-1.517 1.33-2.8 2.062-3.77-2.833-5.481-3.96-10.752.065-12.01 1.436-.445 6.238 4.434 8.965 11.664.202.54.387 1.071.568 1.603.268-2.374.898-5.176 1.901-8.103C33 4.592 37.704-.463 39.112.034c1.418.49 2.03 7.392-.497 14.763-.682 2.001-1.47 3.84-2.295 5.46 1.5-1.527 3.199-3.072 5.049-4.575 8.505-6.894 17.88-9.352 19.203-7.696 7.09 8.888-22.447 27.052-29.956 24.827-2.02-.602-6.08-5.011-9.34-10.05-1.628 3.138-4.403 6.4-5.632 6.763-3.638 1.082-17.957-7.72-14.52-12.03z"
      />
    </svg>
  );
}

export default GrassIcon;
