import React, { useState, useEffect, useContext } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Paper, MenuItem, MenuList, IconButton } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { toast } from 'react-toastify';

import {
  getDraftProperty,
  deleteDraftProperty,
} from '../../api/endpoints/properties';
import useFetch from '../../customHooks/useFetch';
import changeRoutesOnKeyPress from './changeRoutesOnKeyPress';
import { removeAuthData } from '../../helpers/axiosRefreshToken';
import {
  RESET_STATE_LISTING_YOUR_HOUSE,
  SET_FLAG_UPDATING_AFTER_PUBLISHING,
  SET_VALUE_BACKEND,
  SET_FILLING_PROGRESS_STATE_FROM_BACKEND,
  SET_FORM_EDITING_FLAG,
} from '../../pages/ListYourHouse/reducer/actionTypes';
import ListYourHouseContext from '../../pages/ListYourHouse/context';
import UserContext from '../../pages/AccountInfo/context';
import { DELETE_USER_INFO } from '../../pages/AccountInfo/reducer/actionTypes';
import { ReactComponent as BasketIcon } from '../../assets/app/basket.svg';
import useStyles from './useStyles';

export default function UserAccountDropDown({
  handleClose,
  handleToggleMenuByKeyboard,
  open,
}) {
  const [isDraftExist, setStateDraft] = useState(false);
  const {
    dispatch,
  } = useContext(ListYourHouseContext);
  const { dispatchUser } = useContext(UserContext);
  const [{ response: responseGetDraft }, doFetchGetDraft] = useFetch(getDraftProperty);
  const [
    { response: responseDeleteDraft, error: errorDeletetDraft },
    doFetchDeleteDraft,
  ] = useFetch(deleteDraftProperty);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    doFetchGetDraft();
  }, [doFetchGetDraft]);

  useEffect(() => {
    if (responseGetDraft) {
      setStateDraft(true);
    }
  }, [responseGetDraft]);

  useEffect(() => {
    if (responseDeleteDraft) {
      setStateDraft(false);
      handleClose();
      toast.success('Deleted', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      dispatch({
        type: RESET_STATE_LISTING_YOUR_HOUSE,
      });
      history.push('/');
    }
  }, [responseDeleteDraft, setStateDraft, handleClose, dispatch, history]);

  useEffect(() => {
    if (errorDeletetDraft) {
      toast.error(errorDeletetDraft.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [errorDeletetDraft]);

  const logOut = () => {
    removeAuthData();
    handleClose();
    dispatchUser({
      type: DELETE_USER_INFO,
    });
    dispatch({
      type: RESET_STATE_LISTING_YOUR_HOUSE,
    });
    history.push('/');
  };

  const handleChangeRoute = (name, e) => {
    if (name === 'logOut') {
      dispatch({
        type: RESET_STATE_LISTING_YOUR_HOUSE,
      });
      dispatchUser({
        type: DELETE_USER_INFO,
      });
    }
    changeRoutesOnKeyPress(name, e, history, removeAuthData);
  };

  const handleResetAndChangeListingContext = () => {
    dispatch({
      type: SET_FORM_EDITING_FLAG,
      payload: false,
    });
    dispatch({
      type: SET_FLAG_UPDATING_AFTER_PUBLISHING,
      payload: false,
    });
    dispatch({
      type: SET_VALUE_BACKEND,
      payload: responseGetDraft,
    });
    dispatch({
      type: SET_FILLING_PROGRESS_STATE_FROM_BACKEND,
      payload: responseGetDraft.fillingProgress,
    });
  };

  const handleChangeOpenDraft = (name, e) => {
    handleResetAndChangeListingContext();
    changeRoutesOnKeyPress(name, e, history);
  };

  const handleChangeOpenDraftClick = () => {
    handleResetAndChangeListingContext();
    history.push('/list-your-house/edit');
    handleClose();
  };

  return (
    <Paper className={classes.paper}>
      <ClickAwayListener onClickAway={handleClose}>
        <MenuList
          autoFocusItem={open}
          id="menu-list-grow"
          onKeyDown={handleToggleMenuByKeyboard}
        >
          {isDraftExist && (
            <MenuItem className={cx(classes.option, classes.draftOption)}>
              <Link
                onKeyDown={(e) => handleChangeOpenDraft('draft', e)}
                onClick={handleChangeOpenDraftClick}
                className={classes.draftLink}
                to="/list-your-house/edit"
              >
                Finish draft
              </Link>
              <IconButton onClick={() => doFetchDeleteDraft()}>
                <BasketIcon />
              </IconButton>
            </MenuItem>
          )}

          <MenuItem
            onKeyDown={(e) => handleChangeRoute('sellerDashboard', e)}
            className={classes.option}
            onClick={handleClose}
          >
            <Link className={classes.link} to="/my-seller-dashboard">
              My Seller Dashboard
            </Link>
          </MenuItem>

          <MenuItem
            onKeyDown={(e) => handleChangeRoute('buyerDashboard', e)}
            className={classes.option}
            onClick={handleClose}
          >
            <Link className={classes.link} to="/my-buyer-dashboard">
              My Buyer Dashboard
            </Link>
          </MenuItem>

          <MenuItem
            onKeyDown={(e) => handleChangeRoute('myAccount', e)}
            className={classes.option}
            onClick={handleClose}
          >
            <Link className={classes.link} to="/my-account">
              My Account Information
            </Link>
          </MenuItem>

          <MenuItem
            onKeyDown={(e) => handleChangeRoute('logOut', e)}
            className={classes.option}
            onClick={logOut}
          >
            Log out
          </MenuItem>
        </MenuList>
      </ClickAwayListener>
    </Paper>
  );
}
