import {
  SET_USER_EMAIL,
  SET_VERIFIED,
  SET_USER_FIRSTNAME,
  SET_USER_LASTNAME,
  SET_USER_INFO,
  DELETE_USER_INFO,
  ADD_DOCUMENTS,
  ADD_DOCUMENT,
  DELETE_DOCUMENTS,
  SET_ACCOUNT_STATUS,
} from './actionTypes';
import { initialState } from '../context';

export default function reducer(state, action) {
  switch (action.type) {
    case SET_VERIFIED:
      return {
        ...state,
        emailVerified: action.payload,
      };
    case SET_ACCOUNT_STATUS:
      return {
        ...state,
        verified: action.payload,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_USER_FIRSTNAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case SET_USER_LASTNAME:
      return {
        ...state,
        lastName: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_USER_INFO:
      return initialState;
    case ADD_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      };
    case ADD_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case DELETE_DOCUMENTS: {
      const newDocumentsArray = state.documents.filter(
        (document) => document.id !== action.payload.id,
      );
      return {
        ...state,
        documents: newDocumentsArray,
      };
    }
    default:
      return state;
  }
}
