/* eslint-disable no-param-reassign */
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios from '../api/instance';
import { API_URL } from '../api/api.config';

const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh-token`;
const MIN_TOKEN_REFRESH_INTERVAL = 1 * 55 * 1000;

export const getSavedAuthData = () => JSON.parse(localStorage.getItem('authHausList') || '{}');

const saveAuthData = (data) => localStorage.setItem('authHausList', JSON.stringify(data));

export const removeAuthData = () => {
  localStorage.removeItem('authHausList');
  delete axios.defaults.headers.common.Authorization;
}; 

export const isAuthenticated = () => !!getSavedAuthData()?.accessToken;

export const isEmailVerified = () => getSavedAuthData()?.emailVerified;

export const getIdUser = () => getSavedAuthData()?.userId;

export const saveTokens = (axiosData) => {
  const { accessToken, user: { id: userId }, refreshToken, emailVerified } = axiosData;

  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  
  saveAuthData({ userId, accessToken, refreshToken, emailVerified });
  return Promise.resolve(axiosData);
};

// Refresh request itself
let previousRefreshTokenPromise = null;
let refreshTokenTimeout = null;

const refreshAuthToken = () => {
  const { refreshToken, userId } = getSavedAuthData();
  // Do not execute if have no token
  if (!refreshToken || !userId) {
    removeAuthData();
    return Promise.reject();
  }
  // Make just one refresh request for all failed requests
  if (previousRefreshTokenPromise) {
    return previousRefreshTokenPromise;
  }

  clearTimeout(refreshTokenTimeout);
  previousRefreshTokenPromise = axios
    .post(REFRESH_TOKEN_URL, {
      userId,
      refreshToken,
    })
    .catch((error) => {
      removeAuthData();
      Promise.reject(error);
    })
    .finally(() => {
      // Do not refresh token few times during lifetime
      // for example, if additional response was received after refreshing
      refreshTokenTimeout = setTimeout(() => {
        clearTimeout(refreshTokenTimeout);
        previousRefreshTokenPromise = null;
      }, MIN_TOKEN_REFRESH_INTERVAL);
    });
  return previousRefreshTokenPromise;
};

// Intercept unauth responses, refresh token and recall request
const refreshTokenInterceptor = (error) => {
  // Do not refresh on external fails or refresh request
  if (
    !error.config.baseURL.includes(API_URL)
    || error.config.url.includes(REFRESH_TOKEN_URL)
  ) {
    return Promise.reject(error);
  }

  return refreshAuthToken()
    .then((res) => {
      saveTokens(res.data);
      return res.data;
    })
    .then((axiosData) => {
      error.config.headers.Authorization = axios.defaults.headers.common.Authorization;
      return axiosData;
    })
    .catch(() => {
      removeAuthData();
      Promise.reject(error);
    });
};

createAuthRefreshInterceptor(axios, refreshTokenInterceptor, {
  pauseInstanceWhileRefreshing: true, // default: false
});

const { accessToken } = getSavedAuthData();

if (accessToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${
    accessToken
  }`;
}
if (!accessToken) {
  delete axios.defaults.headers.common.Authorization;
}

export default saveTokens;
