import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.7rem 1rem',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flex: '1',
    boxShadow: 'none',
    '& .MuiInputBase-root': {
      fontSize: '1.6rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.5rem',
        width: '100%',
      },
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: '#2b2d40',
    fontFamily: '"Oxygen", sans-serif',
  },
  iconButton: {
    height: '2rem',
    color: '#C92B32',
  },
  loading: {
    fontSize: '1.4rem',
  },
}));
export default useStyles;
