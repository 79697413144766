import React from 'react';

export const initialState = {
  email: '',
  name: '',
  emailVerified: false,
  firstName: '',
  lastName: '',
  documents: [],
  verified: false,
};
const UserContext = React.createContext(initialState);

export default UserContext;
