/* eslint-disable consistent-return */
import { toast } from 'react-toastify';
import axios from 'axios';
import { ENDPOINTS, API_URL } from './api.config';

const GET_DRAFT_URL = ENDPOINTS.draft;

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.response.use(
  (config) => config,
  (err) => {
    const errorAuth = err?.response?.status === 401;
    const errorNotFound = err?.response?.status === 404;

    const errorMessageBackEnd = err?.response?.status === 400 && err.response.data.errors;

    const errorMessage = err?.response?.data?.message
      ? err.response.data.message
      : err.message;

    const errorGetDraft = err.config.url.includes(GET_DRAFT_URL);
    const errorGetConveyancer = err.config.url.includes('conveyancer');

    if (errorAuth || errorMessageBackEnd || (errorGetDraft && errorNotFound) || (errorGetConveyancer && errorNotFound)) {
      return Promise.reject(err);
    }

    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  },
);

export default instance;
