const API_URL = 'https://staging-api.hauslist.co.uk';

const ENDPOINTS = {
  login: './auth/login',
  signup: './users',
  resetPassword: './auth/forgot-password',
  verifyEmail: '/users/verify-email',
  resentEmail: '/users/resend-email',
  user: '/users/me',
  users: '/users',
  properties: '/properties',
  draft: '/properties/draft',
  services: '/services',
  messages: '/messages',
  timeSlots: '/time-slots',
  viewings: '/viewings',
  conveyancers: '/conveyancers',
  faq: '/faq',
};

export { API_URL, ENDPOINTS };
