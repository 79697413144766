export const SET_USER_EMAIL = 'USER/SET_USER_EMAIL';
export const SET_VERIFIED = 'USER/SET_EMAIL_VERIFIED'; // email-status
export const SET_USER_FIRSTNAME = 'USER/SET_USER_FIRSTNAME';
export const SET_USER_LASTNAME = 'USER/SET_USER_FIRSTNAME';
export const SET_USER_INFO = 'USER/SET_USER_INFO';
export const DELETE_USER_INFO = 'USER/DELETE_USER_INFO';
export const SET_ACCOUNT_STATUS = 'USER/SET_ACCOUNT_STATUS';

export const ADD_DOCUMENT = 'USER/ADD_DOCUMENT';
export const ADD_DOCUMENTS = 'USER/ADD_DOCUMENTS';
export const DELETE_DOCUMENTS = 'USER/DELETE_DOCUMENTS';
