/* eslint-disable implicit-arrow-linebreak */
import {
  SET_INPUT_VALUE,
  SET_CHECKBOX_VALUE,
  CLEAR_ADDRESS_FORM_VALUE,
  SET_VALUE_BACKEND,
  SET_FILLING_PROGRESS_STATE,
  SET_FILLING_PROGRESS_STATE_FROM_BACKEND,
  SET_GEOLOCATION,
  SET_FORM_EDITING_FLAG,
  RESET_STATE_LISTING_YOUR_HOUSE,
  SET_INPUT_VALUE_GOOGLE,
  SET_INITIAL_PROPERTY_TYPE,
  SET_PARKING_VALUE,
  DELETE_PARKING_VALUES,
  SET_PARKING_VALUE_FLAG,
  ADD_PHOTO,
  DELETE_PHOTO,
  SET_MAIN_PHOTO,
  ADD_FLOORPLAN,
  DELETE_FLOORPLAN,
  ADD_DOCUMENTS,
  DELETE_DOCUMENTS,
  SET_SERVICES,
  SET_FLAG_UPDATING_AFTER_PUBLISHING,
} from './actionTypes';
import { initialState } from '../context';

export default function reducer(state, action) {
  switch (action.type) {
    // for all input values on all steps of saving listing
    case SET_INPUT_VALUE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case SET_INPUT_VALUE_GOOGLE:
      return {
        ...state,
        ...action.payload,
      };
    // for all checkboxes values on all steps of saving listing
    case SET_CHECKBOX_VALUE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case CLEAR_ADDRESS_FORM_VALUE:
      return {
        ...state,
        building: '',
        streetName: '',
        city: '',
        county: '',
        country: '',
        postcode: '',
        phoneNumber: '',
        hideBuilding: false,
        geolocation: { latitude: 0, longitude: 0 },
      };
    case SET_VALUE_BACKEND:
      return { ...state, ...action.payload };
    case SET_FILLING_PROGRESS_STATE:
      return {
        ...state,
        fillingProgressFrontEnd: action.payload.progress,
        [action.payload.name]: action.payload.name,
      };
    case SET_FILLING_PROGRESS_STATE_FROM_BACKEND:
      return {
        ...state,
        fillingProgressFrontEnd: action.payload,
      };
    case SET_GEOLOCATION:
      return {
        ...state,
        geolocation: action.payload,
      };
    case SET_FORM_EDITING_FLAG:
      return {
        ...state,
        editForm: action.payload,
      };
    case RESET_STATE_LISTING_YOUR_HOUSE:
      return initialState;
    case SET_INITIAL_PROPERTY_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PARKING_VALUE: {
      const parkingArray = [...state.parking];
      let newParkingArray;
      if (!parkingArray.includes(action.payload.name)) {
        newParkingArray = [...parkingArray, action.payload.name];
      } else {
        newParkingArray = parkingArray.filter(
          (value) => value !== action.payload.name,
        );
      }
      return {
        ...state,
        parking: newParkingArray,
        isParking: 'true',
        [action.payload.name]: action.payload.value,
      };
    }
    case DELETE_PARKING_VALUES:
      return {
        ...state,
        parking: [],
        isParking: 'false',
        garage: false,
        coveredParking: false,
        privateSpace: false,
      };
    case SET_PARKING_VALUE_FLAG:
      return {
        ...state,
        isParking: action.payload,
        garage: true,
        parking: [...state.parking, 'garage'],
      };
    case ADD_PHOTO:
      return {
        ...state,
        photos: [...state.photos, action.payload],
      };
    case DELETE_PHOTO: {
      const newPhotosArray = state.photos.filter(
        (photo) => photo.id !== action.payload.id,
      );
      return {
        ...state,
        photos: newPhotosArray,
      };
    }
    case SET_MAIN_PHOTO: {
      const updateMainPhoto = (array, id) =>
        array.map((item) => {
          if (item.id !== id) {
            return {
              ...item,
              isMain: false,
            };
          }
          return {
            ...item,
            isMain: true,
          };
        });
      const newPhotosArray = updateMainPhoto(state.photos, action.payload.id);
      return {
        ...state,
        photos: newPhotosArray,
      };
    }
    case ADD_FLOORPLAN:
      return {
        ...state,
        floorplans: [...state.floorplans, action.payload],
      };
    case DELETE_FLOORPLAN: {
      const newFloorplansArray = state.floorplans.filter(
        (floorplan) => floorplan.id !== action.payload.id,
      );
      return {
        ...state,
        floorplans: newFloorplansArray,
      };
    }
    case ADD_DOCUMENTS:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      };
    case DELETE_DOCUMENTS: {
      const newDocumentsArray = state.documents.filter(
        (document) => document.id !== action.payload.id,
      );
      return {
        ...state,
        documents: newDocumentsArray,
      };
    }
    case SET_SERVICES: {
      const servicesArray = [...state.services];
      let newServicesArray;
      if (!servicesArray.includes(action.payload.name)) {
        newServicesArray = [...servicesArray, action.payload.name];
      } else {
        newServicesArray = servicesArray.filter(
          (value) => value !== action.payload.name,
        );
      }
      return {
        ...state,
        services: newServicesArray,
      };
    }
    case SET_FLAG_UPDATING_AFTER_PUBLISHING:
      return {
        ...state,
        isUpdatingAfterPublishing: action.payload,
      };
    default:
      return state;
  }
}



