import axios from '../instance';
import { ENDPOINTS } from '../api.config';

export async function getProperty(id) {
  return axios.get(`${ENDPOINTS.properties}/${id}`).then((res) => res.data);
}

export async function getProperties(params) {
  return axios
    .get(`${ENDPOINTS.properties}`, { params })
    .then((res) => res.data);
}

export async function getPropertyWithDocuments(id) {
  return axios
    .get(
      `${ENDPOINTS.properties}/${id}?with[]=photos&with[]=floorplans&with[]=documents`,
    )
    .then((res) => res.data);
}
export async function getPropertyWithDocumentsAndConveyancer(id) {
  return axios
    .get(
      `${ENDPOINTS.properties}/${id}?with[]=photos&with[]=floorplans&with[]=documents&with[]=conveyancer`,
    )
    .then((res) => res.data);
}

export async function setProperty(propertyInfo) {
  return axios.post(ENDPOINTS.properties, propertyInfo).then((res) => res.data);
}

export async function changeProperty({ id, propertyTypeData, step }) {
  return axios
    .patch(`${ENDPOINTS.properties}/${id}?step=${step}`, propertyTypeData)
    .then((res) => res.data);
}

export async function getDraftProperty() {
  return axios
    .get(`${ENDPOINTS.draft}?with[]=photos&with[]=floorplans&with[]=documents`)
    .then((res) => res.data);
}

export async function deleteDraftProperty() {
  return axios.delete(ENDPOINTS.draft).then((res) => res.data);
}

export async function getPropertyPhotos(id) {
  return axios
    .get(`${ENDPOINTS.properties}/${id}/photos`)
    .then((res) => res.data);
}

export async function setPropertyPhotoMain({ id, docId }) {
  return axios
    .patch(`${ENDPOINTS.properties}/${id}/photos/${docId}/main`)
    .then((res) => res.data);
}

export async function deletePropertyPhoto({ id, docId }) {
  return axios
    .delete(`${ENDPOINTS.properties}/${id}/photos/${docId}`)
    .then((res) => res.data);
}

export async function deleteFloorPlan({ id, docId }) {
  return axios
    .delete(`${ENDPOINTS.properties}/${id}/floorplan/${docId}`)
    .then((res) => res.data);
}

export async function deleteDocuments({ id, docId }) {
  return axios
    .delete(`${ENDPOINTS.properties}/${id}/documents/${docId}`)
    .then((res) => res.data);
}

export async function changeState({ id, data }) {
  return axios
    .patch(`${ENDPOINTS.properties}/${id}/progress`, data)
    .then((res) => res.data);
}

export async function setServiceChoice({ id, services }) {
  return axios
    .post(`${ENDPOINTS.properties}/${id}/payments`, services)
    .then((res) => res.data);
}

export async function setFinishState(id) {
  return axios
    .post(`${ENDPOINTS.properties}/${id}/finish`, id)
    .then((res) => res.data);
}

export async function getLastPayment(id) {
  return axios
    .get(`${ENDPOINTS.properties}/${id}/payments?limit=1&sortOrder=desc`)
    .then((res) => res.data);
}

export async function addToFavorites(id) {
  return axios
    .post(`${ENDPOINTS.properties}/${id}/favorites`)
    .then((res) => res.data);
}

export async function deleteFromFavorites(id) {
  return axios
    .delete(`${ENDPOINTS.properties}/${id}/favorites`)
    .then((res) => res.data);
}

export async function getConveyancerInfo(id) {
  return axios
    .get(`${ENDPOINTS.properties}/${id}/conveyancer`)
    .then((res) => res.data);
}

export async function getUserProperties(params) {
  return axios
    .get(`${ENDPOINTS.user}/properties`, { params })
    .then((res) => res.data);
}

export async function getStatistics({ id, params }) {
  return axios
    .get(`${ENDPOINTS.properties}/${id}/statistics`, { params })
    .then((res) => res.data);
}

export async function deleteProperty(id) {
  return axios.delete(`${ENDPOINTS.properties}/${id}`).then((res) => res.data);
}

export async function getFavoritesByUser() {
  return axios.get(`${ENDPOINTS.properties}/favorites`).then((res) => res.data);
}
