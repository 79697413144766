/* eslint-disable no-console */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';
import { HashLink } from 'react-router-hash-link';
import queryString from 'query-string';

import { isAuthenticated } from '../../helpers/axiosRefreshToken';
import createGooglePlaceSearchObject from '../../helpers/googlePlace/createGooglePlaceSearchObject';
import TextFieldAutoComplete from '../TextFieldAutocomplete';
import Logo from '../Logo';
import { RESET_STATE_LISTING_YOUR_HOUSE } from '../../pages/ListYourHouse/reducer/actionTypes';
import getInitials from '../../helpers/getInitials';
import UserAccountDropDown from '../UserAccountDropDown/UserAccountDropDown';
import styles from './navbar.module.scss';

function Navbar({ firstName, lastName, dispatch }) {
  const [isMenuOpen, toggleMenu] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const listYourHouseLink = isAuthenticated()
    ? '/list-your-house/select-draft'
    : '/log-in';

  const reloadThePage = () => {
    if (location.pathname === '/') {
      window.location.reload();
    }
  };

  const handleToggleMenu = () => {
    toggleMenu((stateMenu) => !stateMenu);
  };

  const handleToggleMenuByKeyboard = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      toggleMenu(false);
    }
  };

  const goToListYourHouse = () => {
    dispatch({
      type: RESET_STATE_LISTING_YOUR_HOUSE,
    });
    history.push(listYourHouseLink, { path: 'fromListYourHouse' });
  };

  const handleSearchItems = (address, setAddress) => {
    if (address) {
      geocodeByAddress(address)
        .then(async (results) => {
          const addressInfo = await results[0];
          const coord = await getLatLng(results[0]);
          console.log('addressInfoNav', addressInfo);
          const searchObject = await createGooglePlaceSearchObject(addressInfo);
          const parsed = {
            searchAddressField: address,
            latitude: coord.lat,
            longitude: coord.lng,
            ...searchObject,
          };
          const stringified = queryString.stringify(parsed);

          setAddress('');
          history.push(`/property-search-results?${stringified}`);
        })
        .catch((err) => {
          toast.error(err.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    }
  };

  const initials = getInitials(firstName, lastName) || 'XX';

  return (
    <div className={styles.navbar}>
      <NavLink to="/" onClick={reloadThePage} className={styles.logoLink}>
        <Logo />
      </NavLink>

      <ul className={styles.nav}>
        <li className={styles.navItem}>
          <HashLink
            tabIndex="0"
            smooth
            className={styles.navLink}
            to="/#howItWorks"
          >
            How it Works
          </HashLink>
        </li>
        <li className={styles.navItem}>
          <NavLink className={styles.navLink} to="/faq">
            FAQs
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <HashLink
            tabIndex="0"
            smooth
            className={styles.navLink}
            to="/faq#pricing"
          >
            Pricing
          </HashLink>
        </li>
      </ul>

      <div className={styles.autoCompliteContainer}>
        <TextFieldAutoComplete
          placeholder="Search for properties"
          handleSearchItems={handleSearchItems}
        />
      </div>

      <div className={styles.buttonWrapper}>
        <button
          type="submit"
          className={styles.buttonPrimary}
          onClick={goToListYourHouse}
          tabIndex="0"
        >
          List your house
        </button>
      </div>

      <div className={styles.rightSightHeader}>
        {!isAuthenticated() && (
          <>
            <NavLink className={styles.navLinkSignUp} to="/sign-up">
              Sign up
            </NavLink>
            <NavLink className={styles.navLink} to="/log-in">
              Log in
            </NavLink>
          </>
        )}

        {isAuthenticated() && (
          <button
            type="button"
            onClick={handleToggleMenu}
            className={styles.navLinkUserIcon}
            tabIndex="0"
          >
            {initials}
          </button>
        )}

        {isMenuOpen && (
          <UserAccountDropDown
            handleClose={handleToggleMenu}
            handleToggleMenuByKeyboard={handleToggleMenuByKeyboard}
            open={isMenuOpen}
          />
        )}
      </div>
    </div>
  );
}

const MemoizedNavbar = React.memo(Navbar);
export default MemoizedNavbar;
