/* eslint-disable import/no-extraneous-dependencies */
import React, { Suspense, useContext, useReducer } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PrivateRoute from './routes/privateRoute';
import routes from './routes/routes';
import Wrapper from './components/Wrapper';
import Header from './components/Header';
import Footer from './components/Footer';
import Loader from './components/Loaders/Loader';
import ErrorBoundary from './components/ErrorBoundary';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ListYourHouseContext from './pages/ListYourHouse/context';
import listYourHouseReducer from './pages/ListYourHouse/reducer';
import UserContext from './pages/AccountInfo/context';
import AccountInfoReducer from './pages/AccountInfo/reducer';
import styles from './app.module.scss';

const ListYourHouse = React.lazy(() => import('./pages/ListYourHouse'));
const SellerDashBoard = React.lazy(() => import('./pages/SellerDashBoard'));
const BuyerDashBoard = React.lazy(() => import('./pages/BuyerDashBoard'));
const AccountInfo = React.lazy(() => import('./pages/AccountInfo'));
const Boost = React.lazy(() => import('./pages/Boost'));

function App() {
  const initialStateListYourHouse = useContext(ListYourHouseContext);
  const initialStateUser = useContext(UserContext);

  const [state, dispatch] = useReducer(
    listYourHouseReducer,
    initialStateListYourHouse,
  );
  const [stateUser, dispatchUser] = useReducer(
    AccountInfoReducer,
    initialStateUser,
  );

  return (
    <div className={styles.app}>
      <ListYourHouseContext.Provider value={{ state, dispatch }}>
        <UserContext.Provider value={{ stateUser, dispatchUser }}>
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>

          <Wrapper>
            <Suspense fallback={<Loader />}>
              <ScrollToTop />
              <Switch>
                {routes.map(({ id, path, Component }) => (
                  <Route
                    key={id}
                    exact
                    path={path}
                    render={() => (
                      <ErrorBoundary key={id}>
                        <Component />
                      </ErrorBoundary>
                    )}
                  />
                ))}
                <PrivateRoute
                  path="/list-your-house"
                  component={ListYourHouse}
                />
                <PrivateRoute
                  path="/my-buyer-dashboard"
                  exact
                  component={BuyerDashBoard}
                />
                <PrivateRoute
                  path="/my-account"
                  component={AccountInfo}
                  exact
                />

                <PrivateRoute
                  path="/my-seller-dashboard"
                  exact 
                  component={SellerDashBoard}
                />

                <PrivateRoute
                  path="/boost"
                  component={Boost}
                />

                <Redirect from="*" to="/404" />
              </Switch>
            </Suspense>
          </Wrapper>

          <Footer />
          <ToastContainer className={styles.toast} />
        </UserContext.Provider>
      </ListYourHouseContext.Provider>
    </div>
  );
}

export default App;
