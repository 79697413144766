import axios from '../instance';
import { ENDPOINTS } from '../api.config';

export async function getUserInfo() {
  return axios.get(ENDPOINTS.user).then((res) => res.data);
}

export async function setUserInfo(userInfo) {
  return axios.patch(ENDPOINTS.user, userInfo).then((res) => res.data);
}

export async function deleteUserDocuments(id) {
  return axios
    .delete(`${ENDPOINTS.user}/documents/${id}`)
    .then((res) => res.data);
}

export async function getUserDocuments() {
  return axios
    .get(`${ENDPOINTS.user}/documents`)
    .then((res) => res.data);
}

export async function resentEmail(email) {
  return axios.post(ENDPOINTS.resentEmail, email).then((res) => res.data);
}

export async function verifyEmail(token) {
  return axios.post(ENDPOINTS.verifyEmail, token).then((res) => res.data);
}


