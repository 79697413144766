const selectParameterForRequest = (params) => {
  if (params?.streetName) {
    return ({ streetName: params.streetName });
  }
  if (params?.sublocality_level_1) {
    return ({ sublocality_level_1: params.sublocality_level_1 });
  }
  if ((params?.locality === params?.city) && (params?.locality?.length > 0)) {
    return ({ city: params.city });
  }
  if (params?.locality) {
    return ({ locality: params.locality });
  }
  if (params?.administrative_area_level_4) {
    return ({ administrative_area_level_4: params.administrative_area_level_4 });
  }
  if (params?.administrative_area_level_3) {
    return ({ administrative_area_level_3: params.administrative_area_level_3 });
  }
  if (params?.city) {
    return ({ city: params.city });
  }
  if (params?.county) {
    return ({ county: params.county });
  }
  if (params?.colloquial_area) {
    return ({ colloquial_area: params.colloquial_area });
  }
  if (params?.country) {
    return ({ country: params.country });
  }
  return params;
};

const createGooglePlaceSearchObject = (searchResults) => {
  const searchData = {};
  searchResults?.address_components?.forEach((element) => {
    if (element.types.includes('route')) {
      searchData.streetName = element.long_name;
    }
    if (element.types.includes('sublocality_level_1')) {
      searchData.sublocality_level_1 = element.long_name;
    }
    if (element.types.includes('locality')) {
      searchData.locality = element.long_name;
    }
    if (element.types.includes('administrative_area_level_4')) {
      searchData.administrative_area_level_4 = element.long_name;
    }
    if (element.types.includes('administrative_area_level_3')) {
      searchData.administrative_area_level_3 = element.long_name;
    }
    if (element.types.includes('postal_town')) {
      searchData.city = element.long_name;
    }
    if (element.types.includes('administrative_area_level_2')) {
      searchData.county = element.long_name;
    }
    if (element.types.includes('colloquial_area')) {
      searchData.colloquial_area = element.long_name;
    }
    if (element.types.includes('administrative_area_level_1')) {
      searchData.country = element.long_name;
    }
  });
  return selectParameterForRequest(searchData);
};

export default createGooglePlaceSearchObject;
