import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    zIndex: '999',
    width: '29.5rem',
    borderRadius: '0.9rem',
    top: '8rem',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    '& .MuiList-padding': {
      paddingTop: '0.8rem',
      paddingBottom: '0.8rem',
    },
  },
  option: {
    fontSize: '1.5rem',
    lineHeight: '2.4rem',
    fontFamily: '"Oxygen", sans-serif',
    paddingLeft: '1.2rem',
    paddingBottom: '0.25rem',
    paddingTop: '0.25rem',
    '@media (max-width:1280.98px)': {
      fontSize: '1.2rem',
    },
  },
  draftOption: {
    color: '#C92B32;',
    paddingRight: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '0',
    paddingTop: '0',
    '& .MuiIconButton-root': {
      padding: '0.35rem 0.45rem',
    },
  },
  link: {
    color: '#2B2D40',
    width: '100%',
    height: '100%',
  },
  draftLink: {
    color: '#C92B32;',
  },
}));

export default useStyles;
